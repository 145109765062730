// @ts-nocheck
import { createStore, applyMiddleware, compose } from 'redux';
import creageSagaMiddleware from 'redux-saga';
// import { persistStore, persistReducer } from 'redux-persist';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
// import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

// Reducers
import rootReducer from './rootReducer';

// Sagas
import rootSaga from './rootSaga';
const sagaMiddleware = creageSagaMiddleware();

// History
const history = createBrowserHistory();

// Persistant configuration
// const persistConfig = {
//   key: 'root',
//   storage,
// };

const persistedReducer = rootReducer(history);

function configureStore(initialState: object = {}) {
  const middleWares = [sagaMiddleware, routerMiddleware(history)];
  const middleWareEnhancer = applyMiddleware(...middleWares);

  const enhancers = [middleWareEnhancer];

  let composedEnhancers;
  if (process.env.NODE_ENV === 'development') {
    composedEnhancers = composeWithDevTools(...enhancers);
  } else {
    composedEnhancers = compose(...enhancers);
  }

  const store = createStore(persistedReducer, initialState, composedEnhancers);
  // const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return store;
}

export { configureStore as default, history };
