import React, { MouseEvent, ReactElement, FunctionComponent } from 'react';

// Components
import { Button as AntDButton } from 'antd';

// Types
type TProps = {
  customStyle?: object;
  disabled?: boolean;
  icon?: ReactElement;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  text: string;
  type?: 'link' | 'ghost' | 'default' | 'primary' | 'dashed';
};

const Button: FunctionComponent<TProps> = ({ customStyle, disabled, icon, onClick, text, type = 'default' }) => (
  <AntDButton disabled={disabled} type={type} onClick={onClick} icon={icon} style={customStyle}>
    {text}
  </AntDButton>
);

export default Button;
