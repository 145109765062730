import React, { useRef, FunctionComponent, useEffect } from 'react';
import * as yup from 'yup';

// Components
import { Layout, Typography } from 'antd';
import { AppForm } from 'Components/Molecules';
import { Field, Button } from 'Components/Atoms';
import { getItem } from 'utils/localStorage';

// Styles
import 'antd/lib/layout/style/index.js';
const customStyles = {
  wrapper: {
    width: '50%',
    margin: '2rem auto 0',
  },
};

// Types
type TProps = {
  updateProfileData: Function;
  resetProfileForm: Function;
  i18next: any;
  updateSuccess: boolean;
};

type TUser = {
  name: string;
  email: string;
  id: number;
};

const Profile: FunctionComponent<TProps> = ({ updateProfileData, resetProfileForm, updateSuccess, i18next }) => {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (formRef && formRef.current && updateSuccess) {
      formRef.current.resetForm();
      resetProfileForm();
    }
  }, [updateSuccess, resetProfileForm]);

  const submitHandler = () => {
    let values = {};

    if (formRef && formRef.current) {
      values = formRef.current.getFormValues();
    }
    updateProfileData(values);
  };

  const localStorageUser = getItem('user');
  const parsedUser: TUser = JSON.parse(localStorageUser);
  const { name, email } = parsedUser;

  const profileSchema = yup.object().shape({
    email: yup.string().email(i18next.t('general.email_invalid')).required(i18next.t('general.required_field')),
    name: yup.string().required(i18next.t('general.required_field')),
    old_password: yup.string().when('password', {
      is: value => value && value.length > 0,
      then: yup.string().required(i18next.t('general.required_field')),
      otherwise: yup.string(),
    }),
    password: yup.string().required(i18next.t('general.required_field')).min(15, i18next.t('login.password_minlength')),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password'), undefined], i18next.t('profile_page.password_match'))
      .when('password', {
        is: value => !!value && value.length > 0,
        then: yup.string().required(i18next.t('general.required_field')).min(15, i18next.t('login.password_minlength')),
        otherwise: yup.string(),
      }),
  });

  return (
    <Layout.Content style={customStyles.wrapper}>
      {i18next && (
        <AppForm ref={formRef} onSubmit={submitHandler} validationSchema={profileSchema}>
          <Typography.Title level={1}>{i18next.t('profile_page.title')}</Typography.Title>

          <div className="tbf-form__input-group">
            <Typography.Title level={2}>{i18next.t('profile_page.user_data_title')}</Typography.Title>
            <Field name="email" label={i18next.t('profile_page.label.email')} type="email" defaultValue={email} />
            <Field name="name" label={i18next.t('profile_page.label.name')} defaultValue={name} />
          </div>

          <div className="tbf-form__input-group">
            <Typography.Title level={2}>{i18next.t('profile_page.password_change_title')}</Typography.Title>
            <Field name="old_password" label={i18next.t('profile_page.label.old_password')} type="password" />

            <Field name="password" label={i18next.t('profile_page.label.password')} type="password" />

            <Field
              name="password_confirmation"
              label={i18next.t('profile_page.label.password_confirmation')}
              type="password"
            />
          </div>

          <div className="tbf-form__button-group">
            <Button text={i18next.t('profile_page.button.save')} key="submit" type="primary" />
          </div>
        </AppForm>
      )}
    </Layout.Content>
  );
};

export default Profile;

