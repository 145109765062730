import * as requests from 'api/request';

type TProfileData = {
  email: string;
  name: string;
  old_password: string;
  password?: string;
  password_confirmation?: string;
};

const updateProfileData = (data: TProfileData, token: string, userId: number) => {
  const options = {
    headers: {
      AUTHORIZATION: token,
    },
    data: { ...data },
  };

  return requests.PUT(`${window.serverPath}/api/v1/users/${userId}`, options);
};

export { updateProfileData };
