import React, { FunctionComponent } from 'react';

// Components
import { Typography } from 'antd';

// Types
type Props = {
  text: string;
};

const FieldError: FunctionComponent<Props> = ({ text }) => (
  <Typography.Text type="danger" style={{ display: 'block', minHeight: '1em' }}>
    {text}
  </Typography.Text>
);

export default FieldError;
