import React from 'react';

// Redux store
import configureStore from 'Redux/store';

// Components
import { Provider } from 'react-redux';
import Profile from 'Pages/Profile';

// Store setup
const store = configureStore();

// Typings
type TProps = {
  i18next: any;
};

const App = ({ i18next }: TProps) => (
  <Provider store={store}>
    <Profile i18next={i18next} />
  </Provider>
);

export default App;
