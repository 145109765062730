import * as actionTypes from './actionTypes';

const initialState = {
  updateInProgress: false,
  updateError: false,
  updateSuccess: false,
};

export type State = typeof initialState;
export type Action = {
  type: string;
  payload?: {};
};

// Reducer actions
//

// Update profile data
// - Loading
const updateInProgress = (state: State) => ({
  ...state,
  updateInProgress: true,
  updateError: false,
  updateSuccess: false,
});
// - Error
const updateError = (state: State) => ({
  ...state,
  updateInProgress: false,
  updateError: true,
  updateSuccess: false,
});

// - Success
const updateSuccess = (state: State) => ({
  ...state,
  updateInProgress: false,
  updateError: false,
  updateSuccess: true,
});

const resetForm = () => ({ ...initialState });

// Reducer switch
//
const profileReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROFILE_IN_PROGRESS:
      return updateInProgress(state);
    case actionTypes.UPDATE_PROFILE_ERROR:
      return updateError(state);
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return updateSuccess(state);
    case actionTypes.RESET_PROFILE_FORM_STATE:
      return resetForm();

    default:
      return state;
  }
};

export default profileReducer;
