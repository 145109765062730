import React, { FunctionComponent } from 'react';

// Components
import { Controller, Control } from 'react-hook-form';
import { Input, Typography } from 'antd';
import FieldError from './FieldError';

// Styles
import 'antd/lib/input/style/index.js'
import './style.less';

// Types
type TError = {
  message: string;
  ref: object;
  type: string;
};

type Props = {
  control?: Control;
  defaultValue?: string;
  error?: TError;
  label?: string;
  name: string;
  otherProps?: unknown;
  rules?: object;
  type?: 'text' | 'password' | 'email' | 'textarea';
};

const Field: FunctionComponent<Props> = ({
  control,
  rules,
  defaultValue = '',
  name,
  error,
  label,
  type = 'text',
  otherProps,
}) => (
  <>
    {label && <Typography>{label}</Typography>}
    <Controller
      as={Input}
      control={control}
      rules={rules}
      name={name}
      defaultValue={defaultValue}
      type={type}
      {...otherProps}
      className={`tbf-input ${error ? 'has-error' : ''}`}
    />

    <FieldError text={error?.message || ''} />
  </>
);

export default Field;
