import { connect } from 'react-redux';

// Actions
import * as actions from './state/actionCreators';

// Connected component
import Profile from './Profile';
import { State } from 'Redux/rootReducer';

const mapState = (state: State) => ({
  updateSuccess: state.profile.updateSuccess,
});

const mapDispatch = {
  updateProfileData: actions.updateProfileData,
  resetProfileForm: actions.resetProfileForm,
};

export default connect(mapState, mapDispatch)(Profile);
