import { combineReducers } from 'redux';
import { RouterState } from 'connected-react-router';

// Reducers
import profileReducer, { State as ProfileState } from 'Pages/Profile/state/reducer';

const rootReducer = () =>
  combineReducers({
    profile: profileReducer,
  });

export interface State {
  router: RouterState;
  profile: ProfileState;
}

export default rootReducer;
