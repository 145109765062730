import axios from 'axios';

type TOptions = {
  data?: object;
  headers?: object;
};

const PUT = (url: string, options: TOptions) =>
  axios({
    method: 'PUT',
    url,
    data: options.data,
    headers: options.headers,
  });

const POST = (url: string, options: TOptions) =>
  axios({
    method: 'POST',
    url,
    data: options.data,
    headers: options.headers,
  });

const GET = (url: string, options?: TOptions) =>
  axios({
    method: 'GET',
    url,
    headers: options?.headers,
  });

const DELETE = (url: string, options?: TOptions) =>
  axios({
    method: 'DELETE',
    url,
    headers: options?.headers,
  });

export { GET, PUT, POST, DELETE };
