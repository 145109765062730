export interface StorageItem {
  key: string;
  value: string | object;
}

export class localStorageWorker {
  localStorageSupported: boolean;

  constructor() {
    this.localStorageSupported = typeof window['localStorage'] !== 'undefined' && window['localStorage'] !== null;
  }

  getItem(key: string): unknown {
    let itemValue: unknown;

    if (this.localStorageSupported) {
      itemValue = localStorage.getItem(key);
    }

    return itemValue;
  }
}

export function getItem(key: string): any {
  return localStorage.getItem(key);
}
