import React from 'react';
import ReactDOM from 'react-dom';

// Components
import App from './App';

// Utils
// import * as serviceWorker from './serviceWorker';

// Used to register in AngularJS app
if (!window.TBFLibs) {
  window.TBFLibs = {};
}

window.TBFLibs.TBFProfilePage = function (rootElement: string, serverPath: string, language: string, i18next: any) {
  window.serverPath = serverPath || '';
  window.language = language || 'en';

  window.i18next = i18next || {};

  ReactDOM.render(
    // Reintroduce strict mode when AntD fixes the issue with `findDOMNode`
    // https://github.com/ant-design/ant-design/issues/15193
    //
    // <React.StrictMode>
    <App i18next={i18next} />,
    // </React.StrictMode>
    document.getElementById(rootElement)
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  // serviceWorker.unregister();
};
