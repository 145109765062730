// @ts-nocheck
import { put, takeLatest, call } from 'redux-saga/effects';

// Requests
import * as API from '../api/requests';

// Action types
import * as actionTypes from './actionTypes';
import { getItem } from 'utils/localStorage';
import { readCookie } from 'utils/cookies';
import { notification } from 'antd';

// Typings
type Action = {
  type: typeof actionTypes;
  payload?: object;
};

function* updateProfileData(action: Action) {
  const data = action.payload;

  const localStorageUser = getItem('user');
  const parsedUser = JSON.parse(localStorageUser);
  const { id } = parsedUser;

  const authToken = readCookie('buuqit_token');

  const profileData = {
    user: {
      ...data,
      id,
    },
  };

  try {
    yield put({ type: actionTypes.UPDATE_PROFILE_IN_PROGRESS });

    yield call(API.updateProfileData, profileData, authToken, id);

    yield put({ type: actionTypes.UPDATE_PROFILE_SUCCESS });

    notification.success({
      message: window.i18next.t('profile_nav.profile.alert.user_profile_successfully_updated_js_str'),
    });
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_PROFILE_ERROR });

    if (error?.response && error?.response?.status === 403 && error?.response?.data?.errors) {
      notification.error({
        message: error?.response?.data?.errors[0],
      });
    } else {
      notification.error({
        message: window.i18next.t('general.alert_modals.something_went_wrong_try_again_later_js_str'),
      });
    }
  }
}

export default function* profileSaga() {
  yield takeLatest(actionTypes.UPDATE_PROFILE, updateProfileData);
}
