import * as actionTypes from './actionTypes';

export type TData = {
  email: string;
  name: string;
  id: string | number;
  old_password?: string;
  password?: string;
  password_confirmation?: string;
};

export const updateProfileData = (data: TData) => ({
  type: actionTypes.UPDATE_PROFILE,
  payload: data,
});

export type UpdateProfileDataAction = ReturnType<typeof updateProfileData>;

export const resetProfileForm = () => ({
  type: actionTypes.RESET_PROFILE_FORM_STATE,
});

export type ResetProfileFormAction = ReturnType<typeof resetProfileForm>;
